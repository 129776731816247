<template>
  <div class="track-container" ref="track">
    <v-snackbar v-model="showEvent" multi-line :timeout="4000">
      <div class="d-flex">
        <div v-html="formatedEventData"></div>
        <v-spacer />
        <v-btn
          text
          color="primary"
          @click="$emit('change-view', eventData['linkTo'])"
          v-if="eventData && eventData['type'] == 'node-link'"
        >
          ir
          <v-icon>mdi-chevron-right</v-icon>
        </v-btn>
      </div>
    </v-snackbar>
    <background />
    <animation>
      <div
        :key="view.id"
        v-if="view"
        class="track enter-animation-target leave-animation-target"
      >
        <node-tree
          :component="view.component"
          :nodes="view.nodes"
          @on-event="onEvent"
        />
      </div>
    </animation>
  </div>
</template>
<script>
export default {
  props: ["view", "trackID"],
  data: () => ({ eventData: null, showEvent: false, eventCount: 0 }),
  computed: {
    formatedEventData: function () {
      let format = "";
      if (!this.eventData) return format;
      Object.keys(this.eventData).forEach((key) => {
        if (key == "payload") {
          let payload = this.eventData[key];
          format += "payload:<br />";
          format += `<b>${payload.prop}(${
            payload.valid ? "valid" : "invalid"
          })</b>: ${payload.value}<br />`;
        } else {
          format += `<b>${key}</b>: ${this.eventData[key]}<br />`;
        }
      });
      return format;
    },
  },
  methods: {
    onEvent(event) {
      this.eventData = event;
      this.showEvent = true;
    },
  },
};
</script>
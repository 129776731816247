import store from "../.."
import { fetchView, getViewDoc, getTrackDoc } from "../../../data/track-data";
import JSZip from "jszip";
import FileSaver from "file-saver";

export async function downloadAll(list) {
    var zip = new JSZip();
    list.forEach((element) => {
        zip.file(element.id + ".json", new Blob([JSON.stringify(element.obj)], { type: "text/json" }));
    });
    zip.generateAsync({ type: "blob" })
        .then(function (content) {
            FileSaver.saveAs(content, "data.zip");
        });
}

export async function saveInfo(trackID, info) {
    let doc = getTrackDoc(trackID).collection("data").doc("info");
    await doc.set(info)
}
export async function saveContext(trackID, context) {
    let doc = getTrackDoc(trackID).collection("data").doc("context");
    await doc.set(context)
}
export async function saveChapters(trackID, chapters) {
    let doc = getTrackDoc(trackID).collection("data").doc("chapters");
    await doc.set(chapters)
}

export async function getView(trackID, viewID) {
    if (store.getters.viewID != viewID) {
        let view = await fetchView();
        await setView(trackID, viewID, view)
    }
    return store.getters.view;
}

export async function saveView(trackID, viewID, view) {
    let doc = getViewDoc(trackID, viewID);
    await doc.set(view)
}

export async function setView(viewID, view) {
    store.commit("SET_TRACK_VIEW", { id: viewID, view: view })
}

// export async function viewExists(trackID, viewID) {
//     let doc = await getViewDoc(trackID, viewID).get();
//     return doc.exists;
// }

export async function insertEmptyView(trackID, viewID) {
    let doc = getViewDoc(trackID, viewID);
    await doc.set(emptyView)
}
export async function insertView(trackID, viewID, view) {
    let doc = getViewDoc(trackID, viewID);
    await doc.set(view)
}

export async function deleteView(trackID, viewID) {
    let doc = getViewDoc(trackID, viewID);
    await doc.delete()
}

export async function createTrack(trackID) {
    let doc = getTrackDoc(trackID);
    const requireContext = require.context("@/assets/tracks/pepita/data/views", true, /\.json$/);
    let entryPoint = null;
    requireContext.keys().forEach(async (key) => {
        const path = key.split("/");
        const id = path[path.length - 1].replace(".json", "");
        let viewDoc = doc.collection("views").doc(id);
        if (entryPoint == null) entryPoint = id;
        const obj = requireContext(key);
        await viewDoc.set(obj);
    });
    await doc.collection("data").doc("info").set({ name: "Nova trilha" })
    await doc.collection("data").doc("context").set({ entryPoint: entryPoint })
    await doc.collection("data").doc("chapters").set({ intro: { name: "Início", order: 0, views: [{ id: entryPoint, name: "Introdução" }] } })
}

const emptyView = {
    "component": {
        "options": {
            "fill-height": true
        },
        "is": "v-container"
    },
    "nodes": [
        {
            "component": {
                "is": "v-row",
                "options": {
                    "justify": "center"
                }
            },
            "nodes": [
                {
                    "component": {
                        "is": "v-col",
                        "options": {
                            "cols": 12,
                            "class": "enter-animation-target leave-animation-target"
                        }
                    },
                    "nodes": [
                        {
                            "component": {
                                "options": {
                                    "class": "text-center text-h1 text-uppercase font-weight-bold mb-6"
                                },
                                "content": "Seja muito bem vindo!",
                                "is": "h1"
                            }
                        }
                    ]
                },
                {
                    "nodes": [
                        {
                            "component": {
                                "options": {
                                    "step": {
                                        "is": "button",
                                        "options": {
                                            "class": "pepita-btn"
                                        },
                                        "event": {
                                            "type": "node-link",
                                            "linkTo": ""
                                        }
                                    }
                                },
                                "is": "node-step",
                                "content": "Avançar"
                            }
                        }
                    ],
                    "component": {
                        "options": {
                            "class": "enter-animation-target leave-animation-target",
                            "cols": 3
                        },
                        "is": "v-col"
                    }
                }
            ]
        }
    ]
}
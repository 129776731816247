<template>
  <v-dialog transition="slide-x-transition" width="600" v-model="showPanel">
    <template v-slot:activator="{ on, attrs }">
      <v-btn text v-bind="attrs" v-on="on"> Element Data </v-btn>
    </template>
    <v-card>
      <v-form>
        <v-container>
          <v-row>
            <v-col cols="12">
              <v-text-field v-model="prop" label="Prop"></v-text-field>
            </v-col>
            <v-col cols="9">
              <v-text-field v-model="value" label="Value"></v-text-field>
            </v-col>
            <v-col cols="3">
              <v-switch v-model="valid" label="Valid"></v-switch>
            </v-col>
            <v-col cols="12">
              <v-btn @click="updateElementData">Atualizar</v-btn>
            </v-col>
          </v-row>
        </v-container>
      </v-form>
    </v-card>
  </v-dialog>
</template>
<script>
import { mapMutations } from "vuex";
export default {
  data: () => ({
    showPanel: false,
    prop: null,
    value: null,
    valid: null,
  }),
  methods: {
    ...mapMutations(["SET_TRACK_ELEMENT_DATA"]),
    updateElementData() {
      this.SET_TRACK_ELEMENT_DATA({
        prop: this.prop,
        value: JSON.parse(this.value),
        valid: this.valid,
      });
      this.showPanel = false;
      this.$emit('updated')
    },
  },
};
</script>
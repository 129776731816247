<template>
  <div>
    <v-btn
      text
      :color="showPanel ? 'primary' : 'black'"
      @click="$emit('show-panel')"
    >
      <v-icon class="mr-2">mdi-pencil</v-icon>
      {{ label }}
    </v-btn>
    <v-dialog hide-overlay transition="slide-x-transition" v-model="showPanel">
      <v-card>
        <v-toolbar dense>
          <v-toolbar-items>
            <v-btn text @click="$emit('close-panel')">
              <v-icon>mdi-chevron-left</v-icon>
              Voltar
            </v-btn>
          </v-toolbar-items>
          <v-spacer />
          <div>{{ id }}</div>
          <v-spacer />
          <v-toolbar-items>
            <v-btn text @click="save">
              <v-icon>mdi-content-save-outline</v-icon>
              Salvar
            </v-btn>
          </v-toolbar-items>
        </v-toolbar>
        <div ref="jsoneditor" class="editor"></div>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import JSONEditor from "jsoneditor";

export default {
  props: ["json", "label", "show", "id"],
  data: () => ({
    editor: null,
  }),
  watch: {
    showPanel: function (value) {
      if (value && this.editor == null)
        this.$nextTick(() => {
          this.init();
        });
    },
    json: function (value) {
      if (value && this.editor) this.loadJson(value);
    },
  },
  computed: {
    showPanel: {
      get() {
        return this.show;
      },
      set(value) {
        if (value) {
          this.$emit("show-panel");
        } else {
          this.$emit("close-panel");
        }
      },
    },
  },
  methods: {
    init() {
      this.editor = new JSONEditor(this.$refs.jsoneditor, {
        modes: ["tree", "code"],
        onChange: () => {
          try {
            var json =  this.editor.get();
            this.$emit("update-json", json);
          } catch (err) {
            console.error("JSON is invalid", err);
          }
        },
      });
      this.loadJson(this.json);
    },
    loadJson(json) {
      this.editor.set(json);
    },
    async save() {
      try {
        var json =  this.editor.get();
        this.$emit("save-json", json);
      } catch (err) {
        console.error("JSON is invalid", err);
      }
    },
  },
};
</script>
<style >
@import url("./stylesheet.css");
</style>
<template>
  <div class="editor" v-if="!loading && !notFound">
    <v-toolbar absolute dense :collapse="!showToolbar">
      <v-switch v-model="showToolbar" inset hide-details dense></v-switch>
      <div class="d-flex align-center" style="height: 100%" v-if="showToolbar">
        <v-divider vertical />
        <v-toolbar-items>
          <v-menu offset-y>
            <template v-slot:activator="{ on, attrs }">
              <v-btn text v-bind="attrs" v-on="on">
                <v-icon>mdi-plus-box</v-icon>
                Nova Tela
              </v-btn>
            </template>
            <v-list>
              <v-list-item @click="insertEmptyView">
                <v-list-item-icon>
                  <v-icon>mdi-plus-box</v-icon>
                </v-list-item-icon>
                <v-list-item-title> Nova tela em branco</v-list-item-title>
              </v-list-item>
              <v-list-item @click="insertCopyView">
                <v-list-item-icon>
                  <v-icon>mdi-plus-box-multiple</v-icon>
                </v-list-item-icon>
                <v-list-item-title>Nova tela cópia desta</v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>
        </v-toolbar-items>
        <v-toolbar-items>
          <v-btn text @click="deleteView">
            <v-icon>mdi-delete</v-icon>
            Apagar tela
          </v-btn>
        </v-toolbar-items>
        <v-divider vertical />
        <json-editor
          v-if="currentView"
          :json="currentView"
          :show="showPanel == 'view'"
          :id="viewID"
          label="Editar tela"
          @update-json="updateView"
          @save-json="saveView"
          @show-panel="showPanel = 'view'"
          @close-panel="showPanel = null"
        />
        <span class="text-subtitle ml-4">Tela:</span>
        <v-select
          class="ml-2"
          dense
          filled
          :items="views"
          v-model="viewID"
          hide-details
        >
        </v-select>
        <span class="text-subtitle ml-4">Estado da tela:</span>
        <v-select
          class="ml-2"
          dense
          filled
          :items="[
            { text: 'válido', value: true },
            { text: 'inválido', value: false },
          ]"
          item-text="text"
          item-value="value"
          v-model="validState"
          @change="changeViewState"
          hide-details
        >
        </v-select>
        <element-data @updated="viewerRefresh++" />
        <v-btn icon @click="viewerRefresh++">
          <v-icon v-text="'mdi-refresh'" />
        </v-btn>
        <v-divider vertical />
        <span class="text-subtitle ml-4 ml-2">Módulo:</span>
        <json-editor
          v-if="currentInfo"
          :json="currentInfo"
          :show="showPanel == 'info'"
          label="Info"
          @update-json="updateInfo"
          @save-json="saveInfo"
          @show-panel="showPanel = 'info'"
          @close-panel="showPanel = null"
        />
        <json-editor
          v-if="currentContext"
          :json="currentContext"
          :show="showPanel == 'context'"
          label="Context"
          @update-json="updateContext"
          @save-json="saveContext"
          @show-panel="showPanel = 'context'"
          @close-panel="showPanel = null"
        />
        <json-editor
          v-if="currentChapters"
          :json="currentChapters"
          :show="showPanel == 'chapters'"
          label="Chapters"
          @update-json="updateChapters"
          @save-json="saveChapters"
          @show-panel="showPanel = 'chapters'"
          @close-panel="showPanel = null"
        />
        <v-btn icon @click="downloadAll">
          <v-icon v-text="'mdi-download'" />
        </v-btn>
      </div>
    </v-toolbar>
    <v-dialog persistent width="600px" v-model="formPanel">
      <v-card>
        <v-card-title v-if="formTitle">{{ formTitle }}</v-card-title>
        <v-card-text>
          <v-form v-model="validForm">
            <v-text-field
              :rules="[(v) => !!v || 'Campo obrigatório']"
              label="ID da tela"
              v-model="viewIDForm"
            />
          </v-form>
        </v-card-text>
        <v-card-actions>
          <v-btn @click="formPanel = false" text>Voltar</v-btn>
          <v-spacer />
          <v-btn @click="confirmForm" text color="success">Confirmar</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <viewer
      :view="view"
      :trackID="trackID"
      :key="viewerRefresh"
      @change-view="
        (id) => {
          viewID = id;
        }
      "
    />
    <v-snackbar
      v-model="showMessage"
      :color="message ? message.color : 'dark'"
      :timeout="4000"
    >
      <div
        style="font-size: 1rem"
        class="text-uppercase text-center"
        v-html="message ? message.text : ''"
      ></div>
    </v-snackbar>
  </div>
  <v-container v-else-if="notFound" fluid fill-height>
    <div>
      <div class="text-center">Trilha não encontrada</div>
      <v-btn color="primary" @click="createAndLoad">Criar trilha</v-btn>
    </div>
  </v-container>
</template>
<script>
import JsonEditor from "../components/editor/JsonEditor";
import ElementData from "../components/editor/ElementData.vue";
import Viewer from "../components/editor/Viewer.vue";
import { mapGetters, mapActions, mapMutations } from "vuex";
import {
  createTrack,
  saveInfo,
  saveContext,
  saveChapters,
  saveView,
  insertEmptyView,
  insertView,
  deleteView,
  downloadAll,
} from "../store/track/handlers/editor-handler";
export default {
  props: ["trackID"],
  components: { JsonEditor, Viewer, ElementData },
  data: () => ({
    loading: true,
    notFound: false,
    validState: false,
    showToolbar: false,
    viewID: null,
    currentView: null,
    currentInfo: null,
    currentContext: null,
    currentChapters: null,
    showMessage: false,
    message: null,
    formPanel: false,
    formTitle: null,
    trackIDForm: null,
    viewIDForm: null,
    validForm: false,
    showPanel: null,
    viewerRefresh: 0,
    onConfirmForm: () => {},
  }),
  computed: {
    ...mapGetters(["trackViews", "trackData", "context", "info", "chapters"]),
    view: function () {
      return this.trackViews[this.viewID];
    },
    views: function () {
      return Object.keys(this.trackViews);
    },
  },
  watch: {
    viewID: function (val) {
      this.currentView = this.trackViews[val];
    },
  },
  mounted() {
    this.load();
  },
  methods: {
    ...mapActions(["fetchTrackData"]),
    ...mapMutations([
      "SET_TRACK_VIEW",
      "SET_TRACK_INFO",
      "SET_TRACK_CONTEXT",
      "SET_TRACK_CHAPTERS",
      "SET_CURRENT_VIEW_VALID_STATE",
    ]),
    async createAndLoad() {
      await createTrack(this.trackID);
      this.load();
    },
    async load() {
      this.notFound = false;
      this.loading = true;
      await this.fetchTrackData(this.trackID);
      if (Object.keys(this.trackViews) == 0) {
        this.notFound = true;
        return;
      }
      if (!this.viewID) this.viewID = this.trackData.context.entryPoint;
      this.currentView = this.trackViews[this.viewID];
      this.currentInfo = this.trackData["info"];
      this.currentContext = this.trackData["context"];
      this.currentChapters = this.trackData["chapters"];
      this.loading = false;
      this.SET_CURRENT_VIEW_VALID_STATE(this.validState);
    },
    updateView(view) {
      this.SET_TRACK_VIEW({ id: this.viewID, view: view });
    },
    updateInfo(info) {
      this.SET_TRACK_INFO(info);
    },
    updateContext(context) {
      this.SET_TRACK_CONTEXT(context);
    },
    updateChapters(chapters) {
      this.SET_TRACK_CHAPTERS(chapters);
    },
    async saveView(view) {
      await saveView(this.trackID, this.viewID, view);
      await this.load();
      this.message = { text: "Tela salva com sucesso!", color: "success" };
      this.showMessage = true;
      this.showPanel = null;
    },
    async saveInfo(info) {
      await saveInfo(this.trackID, info);
      await this.load();
      this.message = { text: "Info salva com sucesso!", color: "success" };
      this.showMessage = true;
      this.showPanel = null;
    },
    async saveContext(context) {
      await saveContext(this.trackID, context);
      await this.load();
      this.message = { text: "Contexto salvo com sucesso!", color: "success" };
      this.showMessage = true;
      this.showPanel = null;
    },
    async saveChapters(chapters) {
      await saveChapters(this.trackID, chapters);
      await this.load();
      this.message = {
        text: "Capítulos salvos com sucesso!",
        color: "success",
      };
      this.showMessage = true;
      this.showPanel = null;
    },
    insertEmptyView() {
      this.onConfirmForm = async () => {
        let exists = this.viewExists(this.viewIDForm);
        if (exists) {
          this.message = {
            text: "Tela já existe, tente outro ID",
            color: "error",
          };
          this.showMessage = true;
          return;
        }
        await insertEmptyView(this.trackID, this.viewIDForm);
        await this.load();
        this.viewID = this.viewIDForm;
        this.message = {
          text: "Tela criada com sucesso",
          color: "success",
        };
        this.showMessage = true;
      };
      this.viewIDForm = this.viewID;
      this.formTitle = "Nova tela em branco";
      this.formPanel = true;
    },
    insertCopyView() {
      this.onConfirmForm = async () => {
        let exists = this.viewExists(this.viewIDForm);
        if (exists) {
          this.message = {
            text: "Tela já existe, tente outro ID",
            color: "error",
          };
          this.showMessage = true;
          return;
        }
        await insertView(this.trackID, this.viewIDForm, this.view);
        await this.load();
        this.viewID = this.viewIDForm;
        this.message = {
          text: "Tela criada com sucesso",
          color: "success",
        };
        this.showMessage = true;
      };
      this.viewIDForm = this.viewID;
      this.formTitle = "Nova tela cópia de: " + this.viewID;
      this.formPanel = true;
    },
    deleteView() {
      this.onConfirmForm = async () => {
        await deleteView(this.trackID, this.viewIDForm);
        await this.load();
        this.message = {
          text: "Tela apagada com sucesso",
          color: "success",
        };
        this.showMessage = true;
      };
      this.viewIDForm = this.viewID;
      this.formTitle = "Apagar tela";
      this.formPanel = true;
    },
    async confirmForm() {
      if (!this.validForm) {
        this.message = {
          text: "Formulário inválido",
          color: "error",
        };
        this.showMessage = true;
        return;
      }
      if (this.viewIDForm == this.viewID && this.formTitle != "Apagar tela") {
        this.message = {
          text: "Tela já existe, tente outro ID",
          color: "error",
        };
        this.showMessage = true;
        return;
      }
      this.formPanel = false;
      this.onConfirmForm();
    },
    viewExists(viewID) {
      return this.trackViews[viewID] != null;
    },
    changeViewState(state) {
      this.SET_CURRENT_VIEW_VALID_STATE(state);
    },
    downloadAll() {
      let list = [];
      list.push({ id: "context", obj: this.context });
      list.push({ id: "info", obj: this.info });
      list.push({ id: "chapters", obj: this.chapters });
      Object.keys(this.trackViews).forEach((key) => {
        list.push({ id: key, obj: this.trackViews[key] });
      });
      downloadAll(list);
    },
  },
};
</script>
<style scoped>
.editor {
  height: 100%;
}
</style>